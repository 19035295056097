/** @jsx jsx */
import PropTypes from 'prop-types'
import {jsx, css} from '@emotion/react'
import {useEffect, useRef} from 'react'
import {colors, socialIcons} from '../constants'

import StartpageLogoNew from '../images/startpage-logo-new.svg'
import {convertDatoCmsImgPathsToLocal} from '../util/datoCmsDataFactory'

const Footer = ({languageCode, links, footerBlogAsset, socialLinksData = socialIcons}) => {
    const startpageLogoLink = useRef(`https://www.startpage.com`)

    const [privacyPolicyLink, aboutUsLink, blogLink, pressLink, startmailLink] = links
    const linksSection = [privacyPolicyLink, aboutUsLink, pressLink, startmailLink]
    if (languageCode === 'de') {
        linksSection.splice(1, 0, {
            text: 'Impressum',
            url: 'https://support.startpage.com/hc/de/articles/4521527855636-Impressum',
        })
    }

    useEffect(() => {
        if (typeof window !== `undefined`) {
            startpageLogoLink.current = `https://www.startpage.com${window.location.href.match('/de') ? '/de' : ''}`
        }
    }, [])

    return (
        <footer
            css={css`
                background-color: ${colors.whisper};
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 35px 50px;
                font-size: 13px;
                color: ${colors.midnight};
                width: 100%;

                @media (max-width: 1000px) {
                    flex-direction: column;
                }
            `}
        >
            <div
                className="footer-left"
                css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media (max-width: 1000px) {
                        order: 3;
                        margin-top: 50px;
                    }

                    p {
                        margin-left: 16px;

                        a {
                            color: ${colors.brandBlue};
                        }
                    }
                `}
            >
                <a href={blogLink.url}>
                    <img src={convertDatoCmsImgPathsToLocal(footerBlogAsset.url)} alt={blogLink.text} />
                </a>
            </div>
            <div
                className="footer-center"
                css={css`
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    @media (max-width: 1000px) {
                        margin: 20px 0;
                    }
                `}
            >
                <a
                    href={startpageLogoLink}
                    alt="home"
                    rel="noopener noreferrer"
                    css={css`
                        display: inherit;
                        margin-bottom: 20px;
                    `}
                >
                    <StartpageLogoNew
                        css={css`
                            @media (max-width: 1000px) {
                                display: block;
                                margin: 0 auto 1rem;
                            }
                        `}
                    />
                </a>
                <div
                    className="footer-links"
                    css={css`
                        display: flex;
                        flex-wrap: wrap;
                    `}
                >
                    {linksSection.map((linkInfo, i) => {
                        const {text, url} = linkInfo
                        return (
                            <a
                                css={css`
                                    text-decoration: none;
                                    margin: 0.5rem;
                                    transition: color 0.3s ease;

                                    &:hover {
                                        cursor: pointer;
                                        color: ${colors.brandBlue};
                                    }
                                `}
                                key={i}
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label={text}
                            >
                                {text}
                            </a>
                        )
                    })}
                </div>
            </div>
            <div
                className="footer-right"
                css={css`
                    text-align: center;

                    a {
                        &:hover svg {
                            circle,
                            path {
                                transition: all 0.3s ease;
                            }

                            circle {
                                fill: ${colors.brandBlue};
                            }

                            path {
                                fill: ${colors.whisperLight};
                            }
                        }
                    }
                    svg {
                        width: 40px;
                        height: 40px;
                        margin-right: 0.5rem;
                    }
                `}
            >
                {socialLinksData.map((socialItem, i) => {
                    const {url, icon, label} = socialItem
                    return (
                        <a href={url} target="_blank" rel="noopener noreferrer" aria-label={label} key={i}>
                            {icon()}
                        </a>
                    )
                })}
            </div>
        </footer>
    )
}

Footer.propTypes = {
    languageCode: PropTypes.string,
    links: PropTypes.array,
}

Footer.defaultProps = {
    languageCode: `en`,
    links: [
        {
            text: `Privacy policy`,
            url: `https://www.startpage.com/en/search/privacy-policy.html`,
        },
        {text: `About us`, url: `https://www.startpage.com/en/about-us.html`},
        {text: `Blog`, url: `https://www.startpage.com/privacy-please/`},
        {text: `Press`, url: `https://www.startpage.com/privacy-please/press/`},
        {text: `StartMail`, url: `https://www.startmail.com/en/startpage/?pk_campaign=startpage`},
    ],
}

export default Footer
