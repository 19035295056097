/** @jsx jsx */
import {useStaticQuery, graphql} from 'gatsby'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import {jsx, css} from '@emotion/react'

const WidgetRatings = ({description, image}) => {
    const site = useStaticQuery(graphql`
        query WidgetRatingsQuery {
            starRatingFirefoxImg: file(relativePath: {eq: "star_rating.png"}) {
                childImageSharp {
                    fixed(height: 22, quality: 80) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `)

    return (
        <div
            css={css`
                max-width: 8.25rem;
                display: flex;
                align-items: center;
            `}
        >
            <div>
                <Img fixed={image || site.starRatingFirefoxImg.childImageSharp.fixed} />
                <p
                    css={css`
                        line-height: 1;
                        margin: 0;
                    `}
                >
                    {description}
                </p>
            </div>
        </div>
    )
}

WidgetRatings.propTypes = {
    image: PropTypes.object,
    description: PropTypes.string,
}

WidgetRatings.defaultProps = {
    description: `4.6 rating`,
}

export default WidgetRatings
