/** @jsx jsx */
import {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {jsx, css} from '@emotion/react'
import {useStaticQuery, graphql} from 'gatsby'

import {isChrome} from '../util/userAgent'

import WidgetInstalls from './widget-installs'
import WidgetRatings from './widget-ratings'

const WidgetInstallsRatings = ({languageCode}) => {
    const site = useStaticQuery(graphql`
        query WidgetInstallsRatingsQuery {
            firefoxLogo: file(relativePath: {eq: "logoFirefox.png"}) {
                childImageSharp {
                    fixed(height: 50, quality: 80) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            chromeLogo: file(relativePath: {eq: "logoChrome.png"}) {
                childImageSharp {
                    fixed(height: 50, quality: 80) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            starRatingFirefoxImg: file(relativePath: {eq: "starRating.png"}) {
                childImageSharp {
                    fixed(height: 22, quality: 80) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            starRatingChromeImg: file(relativePath: {eq: "starRating.png"}) {
                childImageSharp {
                    fixed(height: 22, quality: 80) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            site {
                siteMetadata {
                    ...AddStartpageCom
                }
            }
        }
    `)

    const {installsChrome, installsFirefox, ratingChrome, ratingFirefox} = site.site.siteMetadata.addStartpageCom[
        languageCode
    ]

    const [browserLogoImg, setBrowserLogoImg] = useState(site.firefoxLogo.childImageSharp.fixed)
    const [starRatingImg, setStarRatingImg] = useState(site.starRatingFirefoxImg.childImageSharp.fixed)
    const [installWidgetText, setInstallWidgetText] = useState(installsFirefox)
    const [ratingWidgetDescription, setRatingWidgetDescription] = useState(ratingFirefox)

    useEffect(() => {
        if (isChrome()) {
            setBrowserLogoImg(site.chromeLogo.childImageSharp.fixed)
            setStarRatingImg(site.starRatingChromeImg.childImageSharp.fixed)
            setInstallWidgetText(installsChrome)
            setRatingWidgetDescription(ratingChrome)
        }
    }, [
        site.chromeLogo.childImageSharp.fixed,
        site.starRatingChromeImg.childImageSharp.fixed,
        installsChrome,
        ratingChrome,
    ])

    return (
        <div
            css={css`
                display: flex;
                align-items: center;
            `}
        >
            <WidgetInstalls text={installWidgetText} image={browserLogoImg} />
            <span
                className="separator"
                css={css`
                    background: lightgray;
                    height: 2rem;
                    width: 2px;
                    margin: 0 1rem;
                `}
            />
            <WidgetRatings description={ratingWidgetDescription} image={starRatingImg} />
        </div>
    )
}

WidgetInstallsRatings.propTypes = {
    languageCode: PropTypes.string,
}

WidgetInstallsRatings.defaultProps = {
    languageCode: `en`,
}

export default WidgetInstallsRatings
