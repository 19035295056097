/** @jsx jsx */
import {useEffect, useState} from 'react'
import {jsx, css} from '@emotion/react'
import PropTypes from 'prop-types'
import Lottie from 'react-lottie'

import Button from './button'
import {colors} from '../../../constants'

const Section2 = ({experiment, languageCode, headline, bulletList, tagline, ctaText, privacyAnimation}) => {
    const [lottieOptions, setLottieOptions] = useState({
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet',
        },
    })

    useEffect(() => {
        setLottieOptions((opts) => ({
            ...opts,
            animationData: JSON.parse(privacyAnimation),
        }))
    }, [privacyAnimation])

    return (
        <section
            css={css`
                padding: 6rem 1rem;
                background: ${colors.brandBlue};
                @media (max-width: 900px) {
                    padding: 3rem 1rem 1rem;
                }
            `}
        >
            <div
                className="container"
                css={css`
                    max-width: 1250px;
                    margin: 0 auto;
                    justify-content: space-between;
                    align-items: center;
                    display: flex;
                    @media (max-width: 900px) {
                        display: block;
                        transform: scale(0.85);
                    }
                `}
            >
                <div
                    className="left-side"
                    css={css`
                        display: flex;
                        flex: 0 0 53%;
                        flex-direction: column;
                        justify-content: center;
                    `}
                >
                    <Lottie options={lottieOptions} />
                </div>
                <div
                    className="right-side"
                    css={css`
                        margin: 0 2rem;
                    `}
                >
                    <div
                        css={css`
                            margin: 3rem 0;
                        `}
                    >
                        <h1
                            css={css`
                                font-size: 48px;
                                margin: 0 0 3rem 0;
                                max-width: 500px;
                                color: ${colors.whisperLight};
                                font-weight: 600;
                            `}
                        >
                            {headline}
                        </h1>
                        <div
                            className="checkmark-list"
                            css={css`
                                p {
                                    display: flex;
                                    align-items: center;
                                    margin: 2rem 0;
                                    font-size: 20px;
                                    color: ${colors.whisperLight};

                                    span {
                                        border-radius: 50%;
                                        background: ${colors.midnight};
                                        width: 32px;
                                        height: 32px;
                                        margin-right: 16px;
                                        color: ${colors.whisperLight};
                                        text-align: center;
                                        font-size: 20px;
                                        font-weight: 600;
                                    }
                                }
                                svg {
                                    margin-right: 1rem;
                                    height: 26px;
                                    width: 26px;
                                }
                            `}
                        >
                            {bulletList.map((item, i) => {
                                const {bulletText} = item
                                return (
                                    <p key={i}>
                                        <span>{i + 1}</span> {bulletText}
                                    </p>
                                )
                            })}
                        </div>
                        <p
                            css={css`
                                margin: 1.5rem 0;
                                max-width: 500px;
                                color: ${colors.whisperLight};
                                font-size: 16px;
                                b {
                                    color: ${colors.whisperLight};
                                }
                            `}
                        >
                            {tagline}
                        </p>
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                            `}
                        >
                            <Button
                                text={ctaText}
                                ctaType="cta-2"
                                experiment={experiment}
                                overrideStyles={`
                                    box-shadow: none;
                                    border: 2px solid ${colors.whisperLight};
                                    border-radius: 4px;
                                    transition: all 0.3s ease;

                                    &:hover {
                                        background: ${colors.whisperLight};
                                        color: ${colors.brandBlue};
                                    }
                                `}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

Section2.propTypes = {
    experiment: PropTypes.string,
    languageCode: PropTypes.string,
    headline: PropTypes.string,
    bulletList: PropTypes.array,
    tagline: PropTypes.string,
    ctaText: PropTypes.string,
}

Section2.defaultProps = {
    experiment: `sp-415`,
    languageCode: `en`,
}

export default Section2
