/** @jsx jsx */
import {jsx, css} from '@emotion/react'
import PropTypes from 'prop-types'

import WidgetInstallsRatings from '../../widget-installs-ratings'
import Button from './button'

import CartoonComputer from '../../../images/cartoon-computer.svg'
import StartpageLogoNew from '../../../images/startpage-logo-new.svg'
import CheckmarkIcon from '../../../images/checkmark-icon.svg'
import {isEdge} from '../../../util/userAgent'

const Section1 = ({experiment, languageCode, showStoreInfo, headline, bulletList, tagline, ctaText}) => {
    return (
        <section
            css={css`
                padding: 3rem 1rem 6rem 1rem;
                @media (max-width: 900px) {
                    padding: 0 1rem 6rem;
                }
            `}
        >
            <div
                className="container"
                css={css`
                    max-width: 1200px;
                    margin: 0 auto;
                    justify-content: space-between;
                    display: flex;
                    @media (max-width: 900px) {
                        display: block;
                        transform: scale(0.85);
                        max-width: 600px;
                    }
                `}
            >
                <div className="left-side">
                    <a
                        href={`https://www.startpage.com${window.location.href.match('/de') ? '/de' : ''}`}
                        rel="noopener noreferrer"
                    >
                        <StartpageLogoNew
                            alt="home"
                            css={css`
                                width: 192px;
                            `}
                        />
                    </a>
                    <div>
                        <h1
                            css={css`
                                font-size: 48px;
                                margin: 3rem 0;
                                max-width: 590px;
                                font-weight: 600;
                                letter-spacing: 0.33px;
                            `}
                        >
                            {headline}
                        </h1>
                        <div
                            className="checkmark-list"
                            css={css`
                                p {
                                    display: flex;
                                    align-items: center;
                                    margin: 2rem 0;
                                    font-size: 20px;
                                }
                                svg {
                                    margin-right: 1rem;
                                }
                            `}
                        >
                            {bulletList.map((item, i) => {
                                const {bulletText} = item
                                return (
                                    <p key={i}>
                                        <CheckmarkIcon /> {bulletText}
                                    </p>
                                )
                            })}
                        </div>
                        <p
                            css={css`
                                margin: 1.5rem 0;
                                max-width: 566px;
                                font-size: 16px;
                            `}
                        >
                            {tagline}
                        </p>
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin-top: 2rem;
                            `}
                        >
                            <Button ctaType="cta-1" experiment={experiment} text={ctaText} />
                            {showStoreInfo && (
                                <div
                                    css={css`
                                        transform: scale(0.95);
                                    `}
                                >
                                    {!isEdge() && <WidgetInstallsRatings languageCode={languageCode} />}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div
                    className="right-side"
                    css={css`
                        width: 40%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        @media (max-width: 900px) {
                            width: 100%;
                        }
                    `}
                >
                    <CartoonComputer
                        css={css`
                            display: block;
                            margin: 4rem auto 0;
                            width: 100%;
                        `}
                    />
                </div>
            </div>
        </section>
    )
}

Section1.propTypes = {
    experiment: PropTypes.string,
    languageCode: PropTypes.string,
    showStoreInfo: PropTypes.bool,
    headline: PropTypes.string,
    bulletList: PropTypes.array,
    tagline: PropTypes.string,
    ctaText: PropTypes.string,
}

Section1.defaultProps = {
    experiment: `sp-415`,
    languageCode: `en`,
    showStoreInfo: true,
}

export default Section1
