import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import SEO from '../../seo'
import {logDplWithExtensionParams, EVENTS} from '../../../util/dpl'
import {setExtensionParamsToLocalStorage} from '../../../util/extensionMetadata'

import Section1 from './section1'
import Section2 from './section2'
import Section3 from './section3'
import Section4 from './section4'
import Section5 from './section5'
import Footer from '../../footer'

const Index415 = ({
    experiment,
    languageCode,
    showStoreInfo,
    section1Headline,
    section1List,
    section2Headline,
    section2List,
    ctaText,
    newsCarousel,
    section3Headline,
    section3List,
    section4Headline,
    section5Headline,
    section5Description,
    tagline,
    footerLinks,
    footerBlogAsset,
    privacyAnimation,
}) => {
    const [hasFiredVisit, setHasFiredVisit] = useState(false)

    useEffect(() => {
        if (!hasFiredVisit) {
            setHasFiredVisit(true)
            setExtensionParamsToLocalStorage(experiment)
            logDplWithExtensionParams(EVENTS.StartpagePageLoad, {
                experiment,
            })
        }
    }, [hasFiredVisit, experiment])

    return (
        <>
            <SEO title="Install Startpage Extension" description="The World's Most Private Search Engine" />
            <Section1
                experiment={experiment}
                languageCode={languageCode}
                showStoreInfo={showStoreInfo}
                headline={section1Headline}
                bulletList={section1List}
                tagline={tagline}
                ctaText={ctaText}
            />
            <Section2
                experiment={experiment}
                languageCode={languageCode}
                headline={section2Headline}
                bulletList={section2List}
                tagline={tagline}
                ctaText={ctaText}
                privacyAnimation={privacyAnimation}
            />
            <Section3
                experiment={experiment}
                headline={section3Headline}
                bulletList={section3List}
                tagline={tagline}
                ctaText={ctaText}
            />
            <Section4
                experiment={experiment}
                newsCarousel={newsCarousel}
                headline={section4Headline}
                tagline={tagline}
                ctaText={ctaText}
            />
            <Section5
                experiment={experiment}
                showStoreInfo={showStoreInfo}
                languageCode={languageCode}
                headline={section5Headline}
                description={section5Description}
                tagline={tagline}
                ctaText={ctaText}
            />
            <Footer links={footerLinks} languageCode={languageCode} footerBlogAsset={footerBlogAsset} />
        </>
    )
}

Index415.propTypes = {
    experiment: PropTypes.string,
    languageCode: PropTypes.string,
    showStoreInfo: PropTypes.bool,
    section1Headline: PropTypes.string,
    section1List: PropTypes.array,
    section2Headline: PropTypes.string,
    section2List: PropTypes.array,
    ctaText: PropTypes.string,
    installsChrome: PropTypes.string,
    installsFirefox: PropTypes.string,
    newsCarousel: PropTypes.array,
    ratingChrome: PropTypes.string,
    ratingFirefox: PropTypes.string,
    section3Headline: PropTypes.string,
    section3List: PropTypes.array,
    section4Headline: PropTypes.string,
    section5Headline: PropTypes.string,
    section5Description: PropTypes.string,
    tagline: PropTypes.string,
    footerLinks: PropTypes.array,
}

Index415.defaultProps = {
    experiment: `sp-415`,
    languageCode: `en`,
    showStoreInfo: true,
    section1Headline: `The World's Most Private Search Engine`,
    section1List: [
        {
            bulletText: ``,
        },
    ],
    section2Headline: `How Your Search Stays Private`,
    section2List: [
        {
            bulletText: ``,
        },
    ],
    ctaText: `Add to {}`,
    installsChrome: `210,000+`,
    installsFirefox: `2.5M+`,
    newsCarousel: [
        {
            quote: ``,
            logo: {
                url: ``,
            },
        },
    ],
    ratingChrome: `4.1 rating`,
    ratingFirefox: `4.1 rating`,
    section3Headline: `Private Search Engine Benefits`,
    section3List: [
        {
            bulletText: ``,
        },
    ],
    section4Headline: `What Industry Experts Are Saying`,
    section5Headline: `Our Mission`,
    section5Description: `Startpage's mission is to protect people's worldwide right to privacy. Take back control of your data rights today!`,
    tagline: `Set Startpage as your default search engine to search from your browser.`,
    footerLinks: [
        {
            text: `Privacy policy`,
            url: `https://www.startpage.com/en/search/privacy-policy.html`,
        },
        {text: `About us`, url: `https://www.startpage.com/en/about-us.html`},
        {text: `Blog`, url: `https://www.startpage.com/privacy-please/`},
        {text: `Press`, url: `https://www.startpage.com/privacy-please/press/`},
        {text: `StartMail`, url: `https://www.startmail.com/en/startpage/?pk_campaign=startpage`},
    ],
}

export default Index415
