import {useEffect, useState} from 'react'
import {getUserAgent} from '../util/userAgent'
import {getUrlParameterByName} from '../util/url'

export const getWeightedIndexList = (configs) => {
    return configs.reduce((acc, config, idx) => {
        const weightedList = [...acc]
        const {weight} = config
        for (let i = 0; i < weight; i += 1) {
            weightedList.push(idx)
        }
        return weightedList
    }, [])
}

// Fisher-Yates shuffle
export const shuffle = (array) => {
    const arr = [...array]
    for (let i = arr.length - 1; i > 0; i -= 1) {
        const randIdx = Math.floor(Math.random() * (i + 1)) // random index from 0 to i
        const t = arr[i]
        arr[i] = arr[randIdx]
        arr[randIdx] = t
    }
    return arr
}

export const getWeightedExperiment = (expConfigs) => {
    const weightedIndexList = getWeightedIndexList(expConfigs)
    const shuffledWeightedIndexList = shuffle(weightedIndexList)
    const weightedIndex = shuffledWeightedIndexList[0]
    return expConfigs[weightedIndex]
}

export const getExperimentVariant = (config, ua = getUserAgent()) => {
    const defaults = {...config}
    delete defaults.experiments
    const {experiments} = config
    const expConfigs = experiments[ua]
    if (expConfigs) {
        const featureFlag = getUrlParameterByName('exp') || ''
        if (featureFlag) {
            const forcedConfig = expConfigs.filter(
                (exp) => exp.experiment.toLowerCase() === featureFlag.toLowerCase(),
            )[0]
            if (forcedConfig) {
                return Object.assign(defaults, forcedConfig)
            }
        }
        const exp = getWeightedExperiment(expConfigs)
        // experiment flags should override default flags
        return Object.assign(defaults, exp)
    }
    // if no experiment config found for a certain browser, return defaults
    return defaults
}

export const getExperimentConfig = (conf, ua = getUserAgent()) => {
    return getExperimentVariant(conf, ua)
}

export const isExperiment = (config, experiment) => {
    return config && config.experiment && config.experiment.toLowerCase() === experiment.toLowerCase()
}

export const useExperiment = (conf, ua = getUserAgent()) => {
    const [expConfig, setExpConfig] = useState({})

    useEffect(() => {
        if (!expConfig.experiment) {
            const cfg = getExperimentConfig(conf, ua)
            setExpConfig(cfg)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conf, ua])

    return expConfig
}
