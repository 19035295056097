/** @jsx jsx */
import PropTypes from 'prop-types'
import {jsx, css} from '@emotion/react'

import Quote from '../../images/quote.svg'
import {colors} from '../../constants'

const Card = ({text, imageUrl}) => {
    return (
        <div
            css={css`
                background: ${colors.whisperLight};
                box-shadow: 0px 10px 20px rgba(32, 41, 69, 0.1);
                border-radius: 4px;
                height: 285px;
                padding: 16px;
                margin: 0 32px;

                p {
                    color: ${colors.midnight};
                    font-size: 20px;
                }
            `}
        >
            <Quote
                css={css`
                    position: absolute;
                    top: 16px;
                    left: 46px;
                `}
            />
            <div
                css={css`
                    position: relative;
                    display: flex;
                    align-items: center;
                    height: 100%;
                    p {
                        font-size: 16px;
                        margin-bottom: 0.5rem;
                    }
                `}
            >
                <p>{text}</p>
                <div
                    css={css`
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;

                        img {
                            max-width: 120px;
                            max-height: 56px;
                        }
                    `}
                >
                    <img src={imageUrl} alt="news-logo" />
                </div>
            </div>
        </div>
    )
}

Card.propTypes = {
    text: PropTypes.string,
    imageUrl: PropTypes.string,
}

Card.defaultProps = {
    text: `Startpage is designed to retain your privacy. The engine doesn't collect data, doesn't keep tabs on your movements, and isn't owned by a gigantic corporation`,
    imageUrl: ``,
}

export default Card
