/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react'
import Layout from '../components/layout'
import {isUnsupported} from '../util/userAgent'

import {getExperimentConfig} from '../experimentFramework/experiment'
import EXPERIMENT_CONFIG from '../experimentFramework/experimentConfig.json'

import IndexUnsupported from '../components/unsupported/index-unsupported'
import Index415 from '../components/templates/sp-415/index-415'

const IndexPage = () => {
    const [expConfig, setExpConfig] = useState(null)
    const [showUnsupportedPage, setShowUnsupportedPage] = useState(false)

    useEffect(() => {
        if (!expConfig) {
            setExpConfig(getExperimentConfig(EXPERIMENT_CONFIG))
        }
        if (isUnsupported()) {
            setShowUnsupportedPage(true)
        }
    }, [expConfig])

    const ExperimentComponent = (config) => {
        if (!config) {
            return <></>
        }
        if (showUnsupportedPage) {
            return <IndexUnsupported experiment="unsupported" />
        }
        const {experiment} = config
        // NOTE: leaving this commented as an example of how you add a new experiment
        // if (isExperiment(config, 'sp-415')) {
        //     return <Index415 experiment={experiment} showStoreInfo={false} />
        // }
        return <Index415 experiment={experiment} showStoreInfo={false} />
    }

    return <Layout>{ExperimentComponent(expConfig)}</Layout>
}

export default IndexPage
