/** @jsx jsx */
import {jsx, css} from '@emotion/react'
import PropTypes from 'prop-types'

import Button from './button'

import CartoonPixelMan from '../../../images/cartoon-pixel-man.svg'
import CheckmarkIcon from '../../../images/checkmark-icon.svg'

const Section3 = ({experiment, headline, bulletList, tagline, ctaText}) => {
    return (
        <section
            css={css`
                padding: 6rem 1rem;
                @media (max-width: 900px) {
                    padding: 2rem 1rem 1rem;
                }
            `}
        >
            <div
                className="container"
                css={css`
                    max-width: 1200px;
                    margin: 0 auto;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    @media (max-width: 1200px) {
                        padding: 0px;
                        display: block;
                        transform: scale(0.85);
                    }
                `}
            >
                <div className="left-side">
                    <div>
                        <h1
                            css={css`
                                font-weight: 600;
                                font-size: 48px;
                                margin: 0 0 3rem 0;
                            `}
                        >
                            {headline}
                        </h1>
                        <div
                            className="checkmark-list"
                            css={css`
                                p {
                                    display: flex;
                                    align-items: center;
                                    margin: 2rem 0;
                                    font-size: 20px;
                                }
                                svg {
                                    margin-right: 1rem;
                                }
                            `}
                        >
                            {bulletList.map((item, i) => {
                                const {bulletText} = item
                                return (
                                    <p key={i}>
                                        <CheckmarkIcon /> {bulletText}
                                    </p>
                                )
                            })}
                        </div>
                        <p
                            css={css`
                                margin: 1.5rem 0;
                                max-width: 500px;
                                font-size: 16px;
                            `}
                        >
                            {tagline}
                        </p>
                        <Button text={ctaText} ctaType="cta-3" experiment={experiment} />
                    </div>
                </div>

                <div
                    className="right-side"
                    css={css`
                        @media (min-width: 1101px) {
                            width: 40%;
                        }
                    `}
                >
                    <CartoonPixelMan
                        css={css`
                            display: block;
                            margin: 4rem auto 0;
                            width: 100%;
                            max-width: 504px;
                        `}
                    />
                </div>
            </div>
        </section>
    )
}

Section3.propTypes = {
    experiment: PropTypes.string,
    headline: PropTypes.string,
    bulletList: PropTypes.array,
    tagline: PropTypes.string,
    ctaText: PropTypes.string,
}

Section3.defaultProps = {
    experiment: `sp-415`,
}

export default Section3
