/** @jsx jsx */
import {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {jsx, css} from '@emotion/react'

import {isChrome, isEdge} from '../../../util/userAgent'
import {isNewTabPage} from '../../../util/url'
import {
    chromeExtensionLink,
    firefoxExtensionLink,
    edgeExtensionLink,
    chromeNewTabStoreLink,
    firefoxNewTabStoreLink,
    edgeNewTabStoreLink,
} from '../../../util/extensionStoreLinks'
import {logDplWithExtensionParams, EVENTS} from '../../../util/dpl'
import {colors} from '../../../constants'

const buttonStyles = css`
    font-family: inherit;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    border-radius: 4px;
    display: inline-block;
    letter-spacing: 0.02rem;
    transition: background-color 0.3s cubic-bezier(0, 0.4, 0.74, 0.63);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    background-color: ${colors.brandBlue};
    border: none;
    color: ${colors.whisper};
    padding: 1rem 2rem;
    &:hover {
        cursor: pointer;
        background-color: #838ffe;
    }
`

const Button = ({text, experiment, onClick, ctaType, overrideStyles}) => {
    const [btnText, setBtnText] = useState(text)
    const [installLink, setInstallLink] = useState(firefoxExtensionLink)

    const setStoreLink = () => {
        if (isEdge()) {
            if (isNewTabPage()) {
                setInstallLink(edgeNewTabStoreLink)
            } else {
                setInstallLink(edgeExtensionLink)
            }
        } else if (isChrome()) {
            if (isNewTabPage()) {
                setInstallLink(chromeNewTabStoreLink)
            } else {
                setInstallLink(chromeExtensionLink)
            }
        } else {
            // firefox
            if (isNewTabPage()) {
                setInstallLink(firefoxNewTabStoreLink)
            } else {
                setInstallLink(firefoxExtensionLink)
            }
        }
    }

    useEffect(() => {
        if (isEdge()) {
            setBtnText(text.replace(`{}`, `Edge`))
        } else if (isChrome()) {
            setBtnText(text.replace(`{}`, `Chrome`))
        } else {
            setBtnText(text.replace(`{}`, `Firefox`))
        }
        setStoreLink()
    }, [setBtnText, setInstallLink, text])

    const onBtnClick = () => {
        logDplWithExtensionParams(EVENTS.StartpageClick, {
            type: ctaType,
            experiment,
        }).then(() => {
            window.location.href = installLink
        })
    }

    return (
        <button
            className="conversion-button"
            onClick={onClick || onBtnClick}
            aria-label={btnText}
            css={css`
                ${buttonStyles}
                ${overrideStyles}
            `}
        >
            {btnText}
        </button>
    )
}

Button.propTypes = {
    experiment: PropTypes.string,
    onClick: PropTypes.func,
    ctaType: PropTypes.string,
    text: PropTypes.string,
    overrideStyles: PropTypes.string,
}

Button.defaultProps = {
    experiment: `sp-415`,
    ctaType: `cta-1`,
    text: `Add to Firefox`,
    overrideStyles: ``,
}

export default Button
