/** @jsx jsx */
import {jsx, css} from '@emotion/react'
import PropTypes from 'prop-types'
import {convertDatoCmsImgPathsToLocal} from '../../../util/datoCmsDataFactory'
import Button from './button'

import EmblaCarousel from '../../embla-carousel/EmblaCarousel'
import EmblaCarouselCard from '../../embla-carousel/EmblaCarouselCard'
import {colors} from '../../../constants'

const Section4 = ({experiment, newsCarousel, headline, tagline, ctaText}) => {
    return (
        <section
            css={css`
                padding: 3rem 0;
                background: ${colors.whisper};
                overflow: hidden;
                @media (max-width: 900px) {
                    padding: 4rem 0;
                }
            `}
        >
            <div
                className="container"
                css={css`
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                `}
            >
                <h2
                    css={css`
                        text-align: center;
                        @media (max-width: 900px) {
                            transform: scale(0.85);
                        }
                    `}
                >
                    {headline}
                </h2>
                <div
                    className="carousel-container"
                    css={css`
                        margin: 4rem 0;
                        position: relative;
                    `}
                >
                    <EmblaCarousel autoplay delayLength={5000}>
                        {newsCarousel.map((item, i) => {
                            const {quote, logo} = item
                            return (
                                <EmblaCarouselCard
                                    text={quote}
                                    imageUrl={convertDatoCmsImgPathsToLocal(logo.url)}
                                    key={i}
                                />
                            )
                        })}
                    </EmblaCarousel>
                </div>

                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        @media (max-width: 900px) {
                            transform: scale(0.85);
                        }
                    `}
                >
                    <p
                        css={css`
                            margin-bottom: 32px;
                            text-align: center;
                            font-size: 16px;
                        `}
                    >
                        {tagline}
                    </p>
                    <Button text={ctaText} ctaType="cta-4" experiment={experiment} />
                </div>
            </div>
        </section>
    )
}

Section4.propTypes = {
    experiment: PropTypes.string,
    newsCarousel: PropTypes.array,
    headline: PropTypes.string,
    tagline: PropTypes.string,
    ctaText: PropTypes.string,
}

Section4.defaultProps = {
    experiment: `sp-415`,
}

export default Section4
