/** @jsx jsx */
import {jsx, css} from '@emotion/react'
/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useCallback} from 'react'
import PropTypes from 'prop-types'
import EmblaCarouselReact from 'embla-carousel-react'
import useInterval from './useInterval'
import {DotButton} from './EmblaCarouselButtons'
import './embla.css'

const EmblaCarouselComponent = ({autoplay, delayLength, loop, children}) => {
    const [embla, setEmbla] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [scrollSnaps, setScrollSnaps] = useState([])
    const [delay, setDelay] = useState(delayLength)
    const [isRunning, setIsRunning] = useState(autoplay)

    const scrollTo = useCallback((index) => embla.scrollTo(index), [embla])
    const scrollNext = useCallback(() => embla.scrollNext(), [embla])

    useInterval(
        () => {
            if (selectedIndex === scrollSnaps.length - 1) {
                scrollTo(0)
            } else {
                scrollNext()
            }
        },
        isRunning ? delay : null,
    )

    useEffect(() => {
        const onSelect = () => {
            setSelectedIndex(embla.selectedScrollSnap())
        }
        if (embla) {
            setScrollSnaps(embla.scrollSnapList())
            embla.on('select', onSelect)
            onSelect()
        }
    }, [embla])

    return (
        <div>
            <div className="embla">
                <EmblaCarouselReact className="embla__viewport" emblaRef={setEmbla} options={{loop}} htmlTagName="div">
                    <div
                        className="embla__container"
                        css={css`
                            padding-bottom: 40px;
                        `}
                    >
                        {children.map((Child, index) => (
                            <div className="embla__slide" key={index}>
                                <div className="embla__slide__inner">{Child}</div>
                            </div>
                        ))}
                    </div>
                </EmblaCarouselReact>
                <div className="embla__dots">
                    {scrollSnaps.map((snap, index) => (
                        <DotButton selected={index === selectedIndex} onClick={() => scrollTo(index)} key={index} />
                    ))}
                </div>
            </div>
        </div>
    )
}

EmblaCarouselComponent.propTypes = {
    loop: PropTypes.bool,
}

EmblaCarouselComponent.defaultProps = {
    loop: false,
}

export default EmblaCarouselComponent
