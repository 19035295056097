/** @jsx jsx */
import {jsx, css} from '@emotion/react'
import PropTypes from 'prop-types'

import WidgetInstallsRatings from '../../widget-installs-ratings'
import Button from './button'

import CartoonGlobe from '../../../images/cartoon-globe.svg'
import {isEdge} from '../../../util/userAgent'

const Section5 = ({experiment, showStoreInfo, languageCode, headline, description, tagline, ctaText}) => {
    return (
        <section
            css={css`
                padding: 6rem 1rem;
                @media (max-width: 900px) {
                    padding: 2rem 1rem 6rem;
                }
            `}
        >
            <div
                className="container"
                css={css`
                    max-width: 1200px;
                    margin: 0 auto;
                    display: flex;
                    justify-content: space-between;
                    @media (max-width: 900px) {
                        display: block;
                        transform: scale(0.85);
                    }
                `}
            >
                <div
                    className="left-side"
                    css={css`
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        margin-right: 64px;

                        @media (max-width: 900px) {
                            width: 100%;
                            align-items: center;
                        }
                    `}
                >
                    <CartoonGlobe />
                </div>

                <div className="right-side">
                    <div
                        css={css`
                            margin: 0 auto;
                            @media (max-width: 900px) {
                            }
                        `}
                    >
                        <h2
                            css={css`
                                font-size: 48px;
                                margin: 3rem 0;
                            `}
                        >
                            {headline}
                        </h2>
                        <p
                            css={css`
                                margin: 1.5rem 0 40px;
                                font-size: 20px;
                            `}
                        >
                            {description}
                        </p>
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                                margin-top: 1rem;
                            `}
                        >
                            <Button text={ctaText} ctaType="cta-5" experiment={experiment} />
                            {showStoreInfo && (
                                <div
                                    css={css`
                                        transform: scale(0.95);
                                        margin-left: 3rem;
                                    `}
                                >
                                    {!isEdge() && <WidgetInstallsRatings languageCode={languageCode} />}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

Section5.propTypes = {
    experiment: PropTypes.string,
    showStoreInfo: PropTypes.bool,
    languageCode: PropTypes.string,
    headline: PropTypes.string,
    description: PropTypes.string,
    tagline: PropTypes.string,
    ctaText: PropTypes.string,
}

Section5.defaultProps = {
    experiment: `sp-415`,
    showStoreInfo: true,
}

export default Section5
